<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<!-- 关于我们 -->
		<div class="title">关于我们</div>
		<div class="content" v-html="content"></div>
		<!-- 联系我们 -->
		<div class="title">联系我们</div>
		<div class="itembox">
			<div class="item fcc" v-for="(item,index) in citylist" :key="index">
				<img class="bg" :src="item.imageurl" alt="">
				<div>{{item.title}}</div>
			</div>
		</div>
		<div style="height: 100px;"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				content: '',
				citylist: []
			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.getcontent();
			this.getcitylist();
		},
		methods: {
			//获取轮播图
			getbanner() {
				let data = {
					mark: 'aboutus01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 360 / 1920
						});
					}
				})
			},
			//获取关于我们
			getcontent() {
				let data = {
					type: 'AboutUs'
				}
				this.$api.home.richText(data).then(res => {
					if (res.code == 1) {
						this.content = res.data;
					}
				})
			},
			//获取联系我们
			getcitylist() {
				let data = {
					mark: 'contact01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.citylist = res.data.data
					}
				})
			}
		}
	}
</script>

<style scoped>
	.title {
		width: 200px;
		margin: 50px auto;
		font-size: 32px;
		text-align: center;
	}

	.content {
		width: 1200px;
		margin: 0 auto;
	}

	.itembox {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}

	.itembox .item {
		width: 280px;
		height: 195px;
		position: relative;
		font-size: 40px;
		color: #ffffff;
		margin: 20px 8px;
	}

	.itembox .item .bg {
		width: 280px;
		height: 195px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
</style>